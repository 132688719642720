import { createRouter, createWebHistory } from 'vue-router';

import Home from '../components/Home.vue';

const routes = [
  // prettier-ignore

  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/forgot-password.vue'),
  },
  {
    path: '/doctor-approve/page/:page?',
    name: 'doctor-approve',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/doctor-approve.vue'),
  },
  {
    path: '/orders/page/:page?',
    name: 'orders',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/orders.vue'),
  },
  {
    path: '/admin/order/print/sst/:orderId',
    name: 'sst',
    //- dbs, sst, genetic, swab, stool, urine - these are the only ones that have similar view in the first page. So put them in the single page.
    //- Made a condition getSampleType() to conditionally render different views.
    component: () => import(/* webpackChunkName: "users" */ '../views/orders/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/sst/:orderId',
    name: 'sst-v2',
    //- dbs, sst, genetic, swab, stool, urine - these are the only ones that have similar view in the first page. So put them in the single page.
    //- Made a condition getSampleType() to conditionally render different views.
    component: () => import(/* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/print/dbs/:orderId',
    name: 'dbs',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/dbs/:orderId',
    name: 'dbs-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/print/saliva/1/:orderId',
    name: 'printOneSaliva',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/saliva.vue'),
  },
  {
    path: '/admin/order/v2/print/saliva/1/:orderId',
    name: 'printOneSaliva-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/saliva.vue'),
  },
  {
    path: '/admin/order/print/genetic-or-swab/:orderId',
    name: 'genetic-or-swab',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/genetic-or-swab/:orderId',
    name: 'genetic-or-swab-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/genetic-or-tube/:orderId',
    name: 'genetic-or-tube-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/print/stool/:orderId',
    name: 'stool',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/stool/:orderId',
    name: 'stool-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/print/urine/:orderId',
    name: 'urine',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/urine-strip/:orderId',
    name: 'urine-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/urine-tube/:orderId',
    name: 'urine-tube',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/order/v2/print/adx/:orderId',
    name: 'adx',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/v2/print/all-print.vue'),
  },
  {
    path: '/admin/orders-plus/page/:page?',
    name: 'admin-orders-plus',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/orders-plus.vue'),
  },
  {
    path: '/admin/v2/orders-plus/page/:page?',
    name: 'admin-orders-plus-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/orders-plus.vue'),
  },
  {
    path: '/admin/jiseki/page/:page?',
    name: 'admin-jiseki',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/jiseki.vue'),
  },

  {
    path: '/admin/contact-us/page/:page?',
    name: 'contact-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/contact-us.vue'),
  },
  {
    path: '/admin/v2/contact-us/page/:page?',
    name: 'contact-us-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/contact-us.vue'),
  },
  {
    path: '/admin/partner-with-us',
    name: 'partner-with-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/partner-with-us.vue'),
  },
  {
    path: '/admin/v2/partner-with-us',
    name: 'partner-with-us-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/partner-with-us.vue'),
  },
  {
    path: '/admin/register-a-kit',
    name: 'register-a-kit',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/register-a-kit.vue'),
  },
  // {
  //   path: '/admin/v2/register-a-kit',
  //   name: 'register-a-kit-v2',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/register-a-kit.vue'),
  // },
  {
    path: '/admin/v2/shipping-options',
    name: 'shipping-options-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/shiping-options.vue'),
  },
  {
    path: '/admin/v2/registered-kit',
    name: 'register-a-kit-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/register-a-kit.vue'),
  },
  {
    path: '/admin/subscriptions',
    name: 'subscriptions',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/subscriptions.vue'),
  },
  {
    path: '/admin/v2/subscriptions',
    name: 'subscriptions-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/subscriptions.vue'),
  },
  {
    path: '/admin/carts/page/:page?',
    name: 'carts',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/carts.vue'),
  },
  {
    path: '/admin/v2/carts/page/:page?',
    name: 'carts-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/carts.vue'),
  },
  {
    path: '/admin/products/page/:page?',
    name: 'products',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/products.vue'),
  },
  {
    path: '/admin/v2/products/page/:page?',
    name: 'products-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/products.vue'),
  },
  {
    path: '/admin/articles/page/:page?',
    name: 'articles',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/articles.vue'),
  },
  {
    path: '/admin/v2/articles/page/:page?',
    name: 'articles-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/articles.vue'),
  },
  {
    path: '/admin/blogs/page/:page?',
    name: 'blogs',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/blogs.vue'),
  },
  {
    path: '/admin/v2/blogs/page/:page?',
    name: 'blogs-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/blogs.vue'),
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/users.vue'),
  },
  {
    path: '/admin/v2/users',
    name: 'admin-users-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/users.vue'),
  },
  {
    path: '/admin/coupons',
    name: 'coupons',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/coupons.vue'),
  },
  {
    path: '/admin/v2/coupons',
    name: 'coupons-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/coupons.vue'),
  },
  {
    path: '/admin/login-tracking',
    name: 'admin-login-tracking',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/login-tracking.vue'),
  },
  {
    path: '/admin/v2/login-tracking',
    name: 'admin-login-tracking-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/login-tracking.vue'),
  },
  {
    path: '/orders/vitalleo/page/:page?',
    name: 'vitalleo',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/vitalleo/vitalleo.vue'),
  },
  {
    path: '/orders/lucy-rose-clinic/page/:page?',
    name: 'the-lucy-rose-clinic',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/the-lucy-rose-clinic/the-lucy-rose-clinic.vue'),
  },
  {
    path: '/orders/rxhome-test/page/:page?',
    name: 'rxhomeTest',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/rxhome-test.vue'),
  },
  {
    path: '/orders/label-pdf-generator/page/:page?',
    name: 'labelPdfGenerator',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/label-pdf-generator.vue'),
  },

  {
    path: '/void-label-history/page/:page?',
    name: 'Void-label-history',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/void-label-history.vue'),
  },
  {
    path: '/v2/void-label-history/page/:page?',
    name: 'Void-label-history-v2',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin/v2/void-label-history.vue'),
  },
  {
    path: '/admin/testimonials/page/:page?',
    name: 'testimonials',
    component: () => import('../views/admin/testimonials.vue'),
  },

  // org admin
  {
    path: '/org-admin/orders/page/:page?',
    name: 'org-admin-orders',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/org-admin/orders.vue'),
  },
  {
    path: '/org-admin/products/page/:page?',
    name: 'org-products',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/org-admin/products.vue'),
  },

  // org admin
  {
    path: '/rx-admin-lab',
    name: 'admin-lab',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lab/admin-lab.vue'),
  },
  {
    path: '/ext-lab-admin',
    name: 'lab',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lab/lab.vue'),
  },

  {
    path: '/admin/organisation/new',
    name: 'Add',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/organization/Add.vue'),
  },
  {
    path: '/admin/organizations',
    name: 'View',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/organization/View.vue'),
  },
  {
    path: '/user-access/user-add',
    name: 'user-add',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/user-add.vue'),
  },
  {
    path: '/user-access/users/page/:page?',
    name: 'users',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/users.vue'),
  },
  {
    path: '/user-access/roles-add',
    name: 'roles-add',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/roles-add.vue'),
  },
  {
    path: '/user-access/roles-update',
    name: 'roles-update',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/roles-update.vue'),
  },
  {
    path: '/user-access/profile',
    name: 'profile',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/profile.vue'),
  },
  {
    path: '/user-access/permissions-add',
    name: 'permissions-add',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/user-access/permissions-add.vue'),
  },
  {
    path: '/users/edit',
    name: 'EditUser',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/users/EditUser.vue'),
  },
  {
    path: '/admin/roles/new',
    name: 'AddRoles',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/AddRoles.vue'),
  },
  // {
  //   path: '/admin/roles/update',
  //   name: 'UpdateRolePerm',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "users" */ '../views/admin/UpdateRolePerm.vue')
  // },

  {
    path: '/admin/users/permissions/new',
    name: 'Permissions',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/users/Permissions.vue'),
  },
  {
    path: '/admin/users/permissions',
    name: 'ViewPermissions',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/permissions/View.vue'),
  },
  {
    path: '/admin-stats',
    name: 'admin-stats',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/admin-stats.vue'),
  },
  {
    path: '/orders/clinic',
    name: '',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/orders-clinic.vue'),
  },
  {
    path: '/orders/vitalleo',
    name: '',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/orders-vitalleo.vue'),
  },
  {
    path: '/sample-type-print',
    name: 'sample-type-print',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/sample-type-print.vue'),
  },
  {
    path: '/dbs-sample-type-print',
    name: 'dbs-ample-type-print',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/dbs-sample-type-print.vue'),
  },
  {
    path: '/urine-sample-type-print',
    name: 'urine-ample-type-print',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/urine-sample-type-print.vue'),
  },
  {
    path: '/stool-sample-type-print',
    name: 'stool-ample-type-print',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/orders/print/stool-sample-type-print.vue'),
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// US BioTech LIMS ////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: '/orders/lims/page/:page?',
    name: 'lims',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/lims.vue'),
  },

  {
    path: '/orders/lims/address/checkout',
    name: 'lab_address',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/lab_address.vue'),
  },
  {
    path: '/orders/lims/orders/:page?',
    name: 'lab_orders',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/lims_orders.vue'),
  },
  {
    path: '/thankyou',
    name: 'thankyou_page',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/thankyou.vue'),
  },
  {
    path: '/payment-response/:status',
    name: 'payment_response',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/payment-response-lims.vue'),
  },


];

const router = createRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  // routes,
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0,
  //   };
  // },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('beforeEach:', to, from);
  if (to.path === '/' || to.path === '/login' || to.path.startsWith('/dashboard')) {
    next();
  } else {
    // if (isLoggedIn()) {
    next();
    // } else {
    //   next({ path: '/' });
    // }
  }
});

export default router;
